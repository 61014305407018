@import './styles/bootstrap';
@import './styles/themes/packex';
@import './styles/fonts';
@import './styles/global';
@import './styles/buttons';
@import './styles/forms';
@import './styles/colors';
@import './styles/snackbar';
@import './styles/responsive';
@import './styles/material-override';
@import "~flag-icons/css/flag-icons.min.css";
